import { FoldersActions } from '../actions/foldersActions';
import { UPDATE_TYPES } from '../interfaces';
import { Dashboard, Folder } from '../../../../views/CRUD/types';

const defaultState = {
  dashboards: [],
  folders: [],
};

type UpdateFolder = {
  folder: Folder;
  updateTye: UPDATE_TYPES;
};

type SetFolders = {
  folders: Array<Folder>;
  dashboards: Array<Dashboard>;
};

type ActionProps = {
  type: FoldersActions;
  id?: number;
  data?: Folder | SetFolders | UpdateFolder;
};

export default function foldersReducers(
  state = defaultState,
  action: ActionProps,
) {
  switch (action.type) {
    case FoldersActions.CREATE_FOLDER: {
      return {
        ...state,
        folders: [...state.folders, action.data],
      };
    }
    case FoldersActions.SET_FOLDERS: {
      return {
        // @ts-ignore
        dashboards: action?.data?.dashboards || [],
        // @ts-ignore
        folders: action?.data.folders,
      };
    }
    case FoldersActions.DELETE_FOLDER: {
      return {
        ...state,
        folders: state.folders.filter(({ id }) => id !== action.id),
      };
    }
    case FoldersActions.UPDATE_FOLDER: {
      // @ts-ignore
      const { updatedFolder, updateTye } = action.data;
      if (updateTye && updateTye === UPDATE_TYPES.DASHBOARD) {
        return {
          ...state,
          dashboards: updatedFolder.dashboards,
        };
      }
      const updatedFolders = state.folders.map(prevFolder => {
        // @ts-ignore
        if (prevFolder.id === updatedFolder.result.id) {
          return updatedFolder.result;
        }
        return prevFolder;
      });
      return {
        ...state,
        folders: updatedFolders,
      };
    }
    default:
      return state;
  }
}
