import { SupersetClient } from '@superset-ui/core';
import { Dispatch } from 'redux';
import { ColorPalette } from '../components/ColorPalettesTable/interfaces';

export enum ColorPalettesActions {
  GET_COLOR_PALETTE = 'GET_COLOR_PALETTE',
  CREATE_COLOR_PALETTE = 'CREATE_COLOR_PALETTE',
  UPDATE_COLOR_PALETTE = 'UPDATE_COLOR_PALETTE',
  DELETE_COLOR_PALETTE = 'DELETE_COLOR_PALETTE',
}

export type Actions = {
  /** Тип экшена */
  type: ColorPalettesActions;
  /** Данные для стора */
  data: ColorPalette | ColorPalette[];
  /** Ключ для удаления */
  key?: number;
};

/** Мидлвар на получение палитр */
export const getColorPalettes = () => async (dispatch: Dispatch) => {
  try {
    const response = await SupersetClient.get({
      endpoint: '/api/v1/color_palette/',
    });
    const colorPalettes: ColorPalette = response.json.result.map(
      ({ colors, name, id }: ColorPalette) => ({
        id,
        name,
        colors,
      }),
    );
    return dispatch({
      type: ColorPalettesActions.GET_COLOR_PALETTE,
      data: colorPalettes,
    });
  } catch (error) {
    throw new Error(error);
  }
};

/** Экшен создания палитры */
export const addColorPalette = (data: ColorPalette) => ({
  type: ColorPalettesActions.CREATE_COLOR_PALETTE,
  data,
});

/** Экшен редактирования палитры */
export const updateColorPalette = (data: ColorPalette) => ({
  type: ColorPalettesActions.UPDATE_COLOR_PALETTE,
  data,
});

/** Экшен удаления палитры */
export const removeColorPalette = (key: number) => ({
  type: ColorPalettesActions.DELETE_COLOR_PALETTE,
  key,
});
