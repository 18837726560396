import { Dispatch } from 'redux';
import { SupersetClient } from '@superset-ui/core';

export enum AppVersionActions {
  /** Сеттер версии приложения */
  SET_APP_VERSION = 'SET_APP_VERSION',
}

export const setAppVersion = (version: string) => ({
  type: AppVersionActions.SET_APP_VERSION,
  version,
});

export const getAppVersion = () => async (dispatch: Dispatch) => {
  try {
    const response = await SupersetClient.get({
      endpoint: '/api/v1/version/',
    });

    const appVersion = response.json.version;

    return dispatch(setAppVersion(appVersion));
  } catch (error) {
    throw new Error(error);
  }
};
