import { DEFAULT_CROSS_FILTER_SCOPING } from '../../constants';

/** Получение дефолт метадаты чарт конфига */
export const getDefaultMetadataChartConfig = (
  layerId: number,
  chartId: number,
) => ({
  id: layerId,
  parentId: chartId === layerId ? undefined : chartId,
  crossFilters: {
    scope: DEFAULT_CROSS_FILTER_SCOPING,
  },
});
