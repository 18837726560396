import { AppVersionActions } from '../actions';

interface ActionProps {
  /** Версия приложения */
  version: string;
  /** Тип редьюсера */
  type: AppVersionActions;
}

/** Редьюер версии приложения */
export default function appVersionReducers(state = '', action: ActionProps) {
  switch (action.type) {
    case AppVersionActions.SET_APP_VERSION:
      return action.version;
    default:
      return state;
  }
}
