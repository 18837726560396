import React from 'react';
import { Dashboard } from 'src/dashboard/types';

export type CardFolderCollectionProps = {
  /** Функция, отвечающая за поиск пермишеннов */
  hasPerm?: (permission: string) => boolean;
  /** Рендер карточки дашборда */
  renderDashboardCard?: (dashboard: Dashboard) => React.ReactNode;
  /** Нужно ли рендерить в модальном окне */
  isModalRender?: boolean;
  /** Идентификаторы дашбордов, необходимых добавить в папку */
  dashboardsIdsToFolder?: Array<number>;
  /** Удалить идентификаторы дашбордов, необходимых добавить в папку  */
  resetDashboardsIdsToFolder?: () => void;
};

export enum UPDATE_TYPES {
  DASHBOARD = 'DASHBOARD',
  FOLDER = 'FOLDER',
}

export type ActiveFolder = {
  /** Сеттим активную папку */
  handleActiveFolder: (id: number, name: string) => void;
  /** Активная папка, на которую нажали */
  activeFolder: {
    id: number;
    name: string;
  } | null;
  resetActiveFolder: () => void;
};
