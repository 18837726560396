import { Actions, ColorPalettesActions } from '../actions/colorPalettesActions';
import { ColorPalette } from '../components/ColorPalettesTable/interfaces';

/** Редюсеры цветовой палитры */
export default function colorPalettesReducers(
  state: ColorPalette[] = [],
  action: Actions,
) {
  switch (action.type) {
    case ColorPalettesActions.GET_COLOR_PALETTE:
      return [...state, ...(action.data as ColorPalette[])];
    case ColorPalettesActions.CREATE_COLOR_PALETTE:
      return [...state, action.data];
    case ColorPalettesActions.UPDATE_COLOR_PALETTE:
      return state.map(item => {
        if (item.id === (action.data as ColorPalette).id) {
          return action.data;
        }
        return item;
      });
    case ColorPalettesActions.DELETE_COLOR_PALETTE:
      return [...state].filter(item => item.id !== action.key);
    default:
      return state;
  }
}
